import 'react-multi-carousel/lib/styles.css';

let skills = [
  {
    title: "Front-End",
    allskills: ["React", "Angular", "Javascript", "Bootstrap", "Tailwind CSS", "HTML", "CSS"]
  },
  {
    title: "Back-End",
    allskills: ["Python", "Node.js", "Express.js","SQL Server","Java", "Azure"]
  },
  {
    title: "Data-Analysis",
    allskills: ["Power BI", "Excel", "pandas","numpy","SQL"]
  },
  {
    title: "UI/UX",
    allskills: ["Requirements Gathering","User Research","Wireframing","Prototyping","Mockups"]
  },
  // {
  //   title: "Data-Analysis",
  //   allskills: ["Banners","Typography","Email Design","Logo Design","Infographics"]
  // },
  {
    title: "Tools",
    allskills: ["MS Office", "Figma","Shopify","Wordpress","Adobe Photoshop","Adobe Illustrator"]
  }
]

export const Skills = () => {
  return (
    <section className = "skill" id="skills">
      <div className="-fluid">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <div className="container">
                <div className="row mt-4">
                {skills.map((skillCategory, index) => (
                    <>
                      <div className="skill-type col-12">
                        <h4>{skillCategory.title}:</h4>
                        <div className="skill-name">
                          {/* Map through the individual skills */}
                          {skillCategory.allskills.map((skill, idx) => (
                            <button key={idx}>{skill}</button>
                          ))}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
