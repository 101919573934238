export const Experience = () => {

  return (
      <section className = "experience" id="experience">
      <div className="-fluid">
        <div className="row">
          <div className="col-12">
            <div className="experience-bx wow zoomIn">
              <h2>Professional Experience</h2>
              <div className="container">
                <div className="row mt-4 justify-content-center">
                <div className="experience-type col-12">
                  <div className="row">
                    <div className="col-md-4">
                    <h4>Web Developer</h4>
                    <h6>DesignArt Canada | Montreal, Quebec</h6>
                    <p>March 2023 - Present</p>
                    </div>
                    <div className="col-md-8">
                      <ul>
                        <li>
                          Developed company's Shopify Website <a href="https://www.idesignart.com" target="_blank">(iDesignart)</a> using custom theme and SEO optimization bringing 40% increase in organic sales.
                        </li>
                        <li>
                          Cleaned, and prepared the data of 1M+ rows using advanced Excel(V-lookups and Pivot tables), and Python to help build large-scale SKUs for retail platforms.
                        </li>
                        <li>
                          Handled large-scale design production using automated Adobe Photoshop action scripts, and Adobe Illustrator.
                        </li>
                        <li>
                          Built data reports using Power BI for production departments reducing reporting times by 10 hours per week.
                        </li>
                        <li>
                          Automated processes which took 7 hours and 5 computers to use Python scripts and execute in 40 minutes.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="experience-type col-12">
                  <div className="row">
                    <div className="col-md-4">
                    <h4>Software Engineer</h4>
                    <h6>Orange Business Services | Gurugram</h6>
                    <p>September 2020 - August 2022</p>
                    </div>
                    <div className="col-md-8">
                      <ul>
                        <li>
                          Developed Front End Projects by writing clean, efficient, and maintainable code in React, and Javascript, following the coding standards and best practices.
                        </li>
                        <li>
                          Streamlined unit testing procedures using Java Selenium Web Driver for web automatiion, resulting in 60% reduction in testing time.
                        </li>
                        <li>
                          Collaborated with cross-functional teams to enhance system functionality and quality assurance.
                        </li>
                        <li>
                          Demonstrated proficiency in web design, learning visual design skills with keen attention to detail, color, typography, layout, UI/UX design, wireframes, mockups, and prototypes.  
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}
